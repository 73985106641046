import React, { useState, useEffect, useContext } from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import Api from "./api";
import {ErrorPage, GenerateError} from "./Tools";
import StarRateIcon from '@mui/icons-material/StarRate';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {ReactComponent as FB_social} from '../Images/Icons/FB_social.svg';

function Feedback(props) {

    const [rate, setRate]        = useState(0);
    const [message, setMessage]  = useState('');
    const [is_sent, setIsSent]   = useState(false);
    const [error_resp, setError] = useState(null);
    const [visible, setVisible]  = useState(false);
    const { t, i18n } = useTranslation();

    const sent_message = <div className="centered_text">
        <div className="feedback-title">
            {t('feedback_sent_title')}
        </div>
        <div className="feedback-message">
            {t('feedback_sent_message')}
        </div>
        <div className="centered_text feedback-links">
            <a href="https://www.facebook.com/NinjaLender" target="_blank">
                <FB_social/>
            </a>
        </div>
    </div>;

    function changeRate(val) {
        if(val > 0){
            setVisible(true);
        }
        setRate(val);
    }

    function changeMessage(event) {
        let val = event.target.value;
        setMessage(val);
    }

    function sendFeedback() {
        let request_body = {
            "params": {
                "point": props.point,
                "rate": parseInt(rate),
                "message": message
            }
        };
         Api.post('/feedback/set', request_body)
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0){
                            setIsSent(true);
                        } else {
                            setError(GenerateError(response.errors[0].code));
                        }
                    },
                    (error) => {
                        setError(GenerateError());
                    }
                )
    }
    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else {
        return (
            <div className="feedback-container">
                {is_sent && <div>{sent_message}</div>}
                {is_sent === false &&
                    <>
                        <div className="flex-row-container">
                            <div>
                                <div className="feedback-title">
                                    {t('feedback_title')}
                                </div>
                                <div className="feedback-message">
                                    {t('feedback_rate_us')}
                                </div>
                            </div>

                            <div>
                                <RateBox rate={rate} rate_change={changeRate}/>
                            </div>
                        </div>

                        {visible === true &&
                        <div className="top-margin-20">
                            <div>
                                <input type="text" placeholder={t('feedback_placeholder')} onChange={changeMessage} required={false}/>
                            </div>
                            <div>
                                <div className="flex-center-row-container">
                                    <div className="top-margin bottom-margin">
                                        <button className="nl-button" disabled={!message && !rate} onClick={() => sendFeedback()}>{t('feedback_send')}</button>
                                    </div>
                                </div>
                            </div>
                        </div> }

                    </>
                }
            </div>
        );
    }
}

export default Feedback;

export function RateBox(props){
    const [rates, setRates] = useState([]);

    useEffect(() => {
        if(!props.rate){
            setCurrentRate(0);
        } else {
            setCurrentRate(props.rate);
        }
    },[]);

    function setCurrentRate(rate) {
       if(rate < 0){
           rate = 0;
       }
       if(rate > 5){
           rate = 5;
       }
       let new_rates = [];
       for(let i=1; i<=5; i++){
           new_rates.push(
               <div onClick={() => setCurrentRate(i)} key={i}>
                   { i <= rate ? <StarRateIcon/> : <StarBorderIcon/>}
               </div>
           );
       }
       setRates(new_rates);
       props.rate_change(rate);
    }

    return (
        <div className="rate-box">
            {rates}
        </div>
    );
}