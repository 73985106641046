import React, {useContext} from 'react';
import '../Styles/App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useTranslation, Trans } from 'react-i18next';
import Loader from "react-loader-spinner";
import Api from "./api";
import reactStringReplace from 'react-string-replace';

export function NinjaLoader() {

    return (
        <div>
            <div className="loader-title">
                Loading...
            </div>
            <div className="loader-body">
                <Loader
                    type="TailSpin"
                    color="#1462D8"
                    height={100}
                    width={100}
                />
            </div>
        </div>
    );

}

export function Message(props) {
    const { t, i18n } = useTranslation();
    return (
        <Trans>{props.children}</Trans>
    );

}

export function ErrorPage(props) {
    const { t, i18n } = useTranslation();
    return (
        <div className="error-container">
            <div className="error-title">{t(props.title)}</div>
            <div className="error-text">{t(props.message)}</div>
            <div>{props.action}</div>
        </div>
    );

}

export function LanguageSelector() {
    const { t, i18n } = useTranslation();
    const lang = i18n.language

    const handleLangChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <div className="lang-selector-container">
            <select className="lang-selector minimal" name="lang_change" value={lang} id="lang_change" onChange={handleLangChange}>
                <option value="en">English</option>
                <option value="pl">Polska</option>
            </select>
        </div>

    );
}

/**
 * Sets suggestion value
 * @param value string with value
 * @param id Id of suggestion
 * @param onSuccess Function that need no be executed on api call success
 * @param setError Function in where errors will be set (If errors happened)
 */
export function useSuggestionSet(value, id, onSuccess, setError) {
    Api.post('/loan/set-suggestion', {"params":{"id":id,"value":value}})
        .then(
            (result) => {
                let response = result.data;
                switch (response.code) {
                    case 0:
                        onSuccess(response.data);
                        break;

                    default:
                        setError(GenerateError(response.errors[0].code));
                        break;
                }
            },
            (error) => {
                setError(GenerateError());
            }
        )
}

/**
 * Edit suggestion value
 * @param value string with value
 * @param id Id of suggestion
 * @param onSuccess Function that need no be executed on api call success
 * @param setError Function in where errors will be set (If errors happened)
 */
export function useSuggestionEdit(value, id, onSuccess, setError) {
    Api.post('/loan/edit-suggestion', {"params":{"id":id,"value":value}})
        .then(
            (result) => {
                let response = result.data;
                switch (response.code) {
                    case 0:
                        onSuccess(response.data);
                        break;

                    default:
                        setError(GenerateError(response.errors[0].code));
                        break;
                }
            },
            (error) => {
                setError(GenerateError());
            }
        )
}

export function secondsToDays(seconds) {
    return Math.floor(seconds / (3600 * 24));
}

export function TransformJsonText(props) {
    if(props !== undefined){
        const rows = props.data.map((consent, i) => {
            if(consent.type === 'p') {
                return <JsonTextParagraph key={props.id+'_'+i} data = {consent} id = {props.id}/>
            }
            if(consent.type === 'list') {
                return <JsonTextList key={props.id+'_'+i} data = {consent} id = {props.id}/>
            }
        });
        return (
            <>{rows}</>
        );
    }
}

export function JsonTextParagraph(props) {
    return <p key={'p_'+props.id}>
        {ReplacePlaceholders(props.data.content)}
    </p>
}
export function JsonTextList(props) {
    const elements = props.data.elements .map((element, i) => {
        return <li key={props.id+'_'+i}>
            {ReplacePlaceholders(element.content)}
        </li>
    });

    return <ul key={'list_'+props.id}>{elements}</ul>
}

export function ReplacePlaceholders(string) {
    const { t, i18n } = useTranslation();

    string = reactStringReplace(string, /(@partner_link_start@[\s\S\n]+@partner_link_end@)/g, (match, i) => {
        let link_name = reactStringReplace(match, '@partner_link_start@', (match, i) => {return '';});
        link_name = reactStringReplace(link_name, '@partner_link_end@', (match, i) => {return '';});
        return <a key={i} className="nl-link-black" href="/partners">{link_name}</a>;
    });
    string = reactStringReplace(string, /(@strong_start@[\s\S\n]+@strong_end@)/g, (match, i) => {
        let text = reactStringReplace(match, '@strong_start@', (match, i) => {return '';});
        text = reactStringReplace(text, '@strong_end@', (match, i) => {return '';});
        return <strong>{text}</strong>;
    });
    string = reactStringReplace(string, /(@support_email@)/g, (match, i) => {
        return ''+process.env.REACT_APP_SUPPORT_EMAIL+'';
    });
    string = reactStringReplace(string, /(@privacy_policy_start@[\s\S\n]+@privacy_policy_end@)/g, (match, i) => {
        let text = reactStringReplace(match, '@privacy_policy_start@', (match, i) => {return '';});
        text = reactStringReplace(text, '@privacy_policy_end@', (match, i) => {return '';});
        return <a href={process.env.REACT_APP_PRIVACY_POLICY_PDF} target="_blank">{text}</a>;
    });

    string = reactStringReplace(string, /(@terms_start@[\s\S\n]+@terms_end@)/g, (match, i) => {
        let text = reactStringReplace(match, '@terms_start@', (match, i) => {return '';});
        text = reactStringReplace(text, '@terms_end@', (match, i) => {return '';});
        return <a href={process.env.REACT_APP_TERMS_OF_USE_PDF} target="_blank">{text}</a>;
    });

    return string;

}

export function GenerateError(error_code) {

    switch(error_code) {
        case -98:
            return {
                title: 'expired_token_title',
                message: 'expired_token_message'
            };
            break;
        case -3000204:
            return {
                title: 'hash_not_found',
                message: 'hash_not_found'
            };
            break;
        case -3000203:
            return {
                title: 'hash_expired', /** TODO: new text needed **/
                message: 'hash_expired'
            };
            break;
        case -3000205:
            return {
                title: 'clear_loan_data_title',
                message: 'no_loan_founded'
            };
        case -3010306:
            return {
                title: 'processing_stopped_title',
                message: 'processing_stopped_text',
                action: ''
            };
        case -3010601:
            return {
                title: 'no_loan',
                message: 'no_loan_founded',
                action: ''
            };
        case -3012003:
            return {
                title: 'no_loan',
                message: 'no_loan_founded',
                action: ''
            };
        default:
            return {
                title: 'oops',
                message: 'something_wrong'
            };
            break;
    }
}
