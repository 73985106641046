
import React, { useState, useEffect, useContext } from 'react';
import '../Styles/App.css';
import {Trans, useTranslation} from "react-i18next";
import {ErrorPage, GenerateError, NinjaLoader, useSuggestionSet} from "./Tools";
import {BorrowerContext} from "./App";
import Api from "./api";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import DatePicker from "react-datepicker";
import {SkipButton} from "./HeaderTools";
import ReactTooltip from 'react-tooltip';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
function Suggestions() {

    const borrower_context_data = useContext(BorrowerContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error_resp, setError] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [current_suggestion_index, setCurrentSuggestionIndex] = useState(0);
    const {t, i18n} = useTranslation();
    const Submitter = useSuggestionSet;
    let history = useHistory();

    function skipToNext() {
        history.push('/review');
    }

    function nextSuggestion() {

        let new_index = null;
        for(let i=current_suggestion_index+1; i<=suggestions.length-1; i++){
            if(suggestions[i].is_filled === false){
                new_index = i;
                break;
            }
        }

        if(new_index !== null) {
            setCurrentSuggestionIndex(new_index);
        } else {
            skipToNext();
        }
    }

    function prevSuggestion() {
        if (current_suggestion_index > 0) {
            setCurrentSuggestionIndex(current_suggestion_index-1);
        }
    }

    function selectSuggestion(index) {
        setCurrentSuggestionIndex(index);
    }
    useEffect(() => {
        borrower_context_data.setAdditionalToolsBlock(<SkipButton route="/review"/>);
        return function cleanup() {
            borrower_context_data.setAdditionalToolsBlock(<></>);
        }
    },[]);
    useEffect(() => {



        function getSuggestionData() {
            setIsLoading(true);
            Api.post('/loan/requisite-suggestion', {"params":{"id":borrower_context_data.loan.id}})
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0){
                            setSuggestions(response.data);
                            let first_unfilled_index = null;

                            for (let i=0; i<response.data.length; i++) {
                                if (first_unfilled_index === null) {
                                    if (response.data[i].is_filled === false) {
                                        first_unfilled_index = i;
                                        break;
                                    }
                                }
                            }
                            setCurrentSuggestionIndex(first_unfilled_index);
                        } else {
                            setError(GenerateError(response.errors[0].code));
                        }

                        setIsLoading(false);
                    },
                    (error) => {
                        setIsLoading(false);
                        setError(GenerateError());
                    }
                )
        }
        getSuggestionData();
    }, [i18n.language]);

    function submitSuggestionAnswer(answer) {
        let id = suggestions[current_suggestion_index].id

       const onSuccess = function(data) {
            let new_suggestions = suggestions;
            new_suggestions[current_suggestion_index].is_filled = true;
            new_suggestions[current_suggestion_index].filled_value = answer;
            nextSuggestion();
        }
        if(answer !== null && answer !== '') {
            Submitter(answer,id,onSuccess,setError);
        } else {
            nextSuggestion();
        }

    }

    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else
    {
        if(isLoading) {
            return (<NinjaLoader />);
        } else {
            return (
                <div>
                    <h1>{t('suggestions_title')}</h1>
                    <div className="suggestion-hint">
                        <LightbulbOutlinedIcon className="tools-icon"/>
                        <Trans
                            i18nKey="suggestion_description"
                            components={[<span className="blue-mark-text" />]}
                        />
                    </div>
                    <div className="single-suggestion-container">
                        <SuggestionLineSelector
                            suggestions = {suggestions}
                            current_suggestion_index = {current_suggestion_index}
                            select_suggestion = {selectSuggestion}
                        />
                        <div>
                            <SingleSuggestion
                                current_suggestion={suggestions[current_suggestion_index]}
                                is_next = {current_suggestion_index < suggestions.length-1}
                                next = {nextSuggestion}
                                is_prev = {current_suggestion_index > 0}
                                prev = {prevSuggestion}
                                submit = {submitSuggestionAnswer}
                            />
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default Suggestions;


export function SuggestionLineSelector(props) {

    const [suggestion_line_select, setSuggestionLineSelect] = useState([]);

    useEffect(() => {
        let line = [];
        for (let i=0; i<props.suggestions.length; i++) {
            let class_name = 'suggestion-line-button';
            if (props.suggestions[i].is_filled === true) {
                class_name+=' filled';
            }
            if (props.current_suggestion_index === i) {
                class_name+=' active';
            }
            let line_item = <div className={class_name} key={i} onClick={() => props.select_suggestion(i)}/>
            line.push(line_item);
        }
        setSuggestionLineSelect(line);

    }, [props.current_suggestion_index]);
    return (
        <div className="flex-row-container suggestion-line-container">
            {suggestion_line_select}
        </div>
    );
}

export function SingleSuggestion(props) {

    const {t, i18n} = useTranslation();
    const [answer_value, setAnswerValue] = useState('');
    const [submit_button_title, setSubmitButtonTitle] = useState(t("suggestion_submit"));
    const [submit_button_disabled, setSubmitButtonDisabled] = useState('');
    const [submit_button_tip, setSubmitButtonTip] = useState('');
    const [next_button_tip, setNextButtonTip] = useState('');

    function submit(event) {
        event.preventDefault();
        props.submit(answer_value);
    }

    const changeAnswer = (value) => {
        setAnswerValue(value);
        if(value !== '') {
            setSubmitButtonDisabled('');
        } else {
            setSubmitButtonDisabled('disabled');
        }
    }

    useEffect(() => {
        if(props.current_suggestion) {
            if(!props.is_next){
                setNextButtonTip(t('suggestion_right_last_tooltip'));
            } else {
                setNextButtonTip(t('suggestion_right_tooltip'));
            }


            if(props.current_suggestion.is_filled === true) {
                setSubmitButtonTitle(t("suggestion_submitted"));
                setSubmitButtonDisabled('disabled');
                setAnswerValue(props.current_suggestion.filled_value);
            } else {
                if(!props.is_next){
                    setSubmitButtonTitle(t("suggestion_submit_last"));
                    setSubmitButtonTip(t('suggestion_last_submit_tooltip'));
                } else {
                    setSubmitButtonTitle(t("suggestion_submit"));
                    setSubmitButtonTip(t('suggestion_submit_tooltip'));
                }
                setSubmitButtonDisabled('disabled');
                setAnswerValue('');
            }
        }
    }, [props.current_suggestion]);

    return (
        <>
            {props.current_suggestion !== undefined &&
            <form onSubmit={(e) => submit(e)}>
                <div>
                    <div className="suggestion-title">
                        {props.current_suggestion.requisite.suggestion_question}
                    </div>
                    <div>
                        <SingleSuggestionInput current_suggestion={props.current_suggestion} onChange={changeAnswer} value={answer_value}/>
                    </div>
                    <div className="flex-row-container suggestion-tools-container">
                        <div className="div-suggestion-tools-small">
                            {props.is_prev &&
                                <>
                                    <a className="nl-button-tolls-small" data-tip data-for="leftTip" onClick={() => props.prev()}><KeyboardArrowLeftOutlinedIcon/></a>
                                    <ReactTooltip id="leftTip" place="top" effect="solid">
                                        {t('suggestion_left_tooltip')}
                                    </ReactTooltip>
                                </>

                            }
                        </div>
                        <div>

                            <button
                                data-tip
                                data-for="submitTip"
                                className="nl-button-white-bordered"
                                disabled={submit_button_disabled}
                            >
                                {submit_button_title}
                            </button>
                            {props.current_suggestion.is_filled !== true &&
                                <ReactTooltip id="submitTip" place="top" effect="solid">
                                    {submit_button_tip}
                                </ReactTooltip>
                            }


                        </div>
                        <div className="div-suggestion-tools-small">
                            <a className="nl-button-tolls-small" data-tip data-for="rightTip" onClick={() => props.next()}><ChevronRightOutlinedIcon/></a>
                            <ReactTooltip id="rightTip" place="top" effect="solid">
                                {next_button_tip}
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
            </form>
            }
        </>
    )
}


export function SingleSuggestionInput(props) {

    const {t, i18n} = useTranslation();

    const [variants, setVariants] = useState([]);
    const [date_value, setDateValue] = useState(null);
    const [disabled, setDisabled] = useState(false);


    useEffect(() => {
        if (props.current_suggestion) {
            if (props.current_suggestion.requisite.variants) {

                let rows = props.current_suggestion.requisite.variants.map(function(itm, number){
                    return <option key={itm.label} value={itm.label}> {itm.title} </option>
                });
                setVariants(rows);
            }
            if ((props.is_edit === undefined || props.is_edit === false) && props.current_suggestion.is_filled) {
                setDisabled(true);

                if(props.current_suggestion.requisite.kind === 3) { //date
                    let dateString = props.current_suggestion.filled_value;
                    setDateValue(new Date(dateString));
                }
            } else {
               setDisabled(false);
            }
        }

    },[props.current_suggestion]);

    function dateChange(date) {

        setDateValue(date);

        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        //const string_date = dd + '.' + mm + '.' + yyyy;
        const string_date = yyyy + '-' + mm + '-' + dd;

        props.onChange(string_date);
    }

    switch (props.current_suggestion.requisite.kind) {
        case 0: // string
        case 1: //text
            return (
                <input placeholder={t('suggestion_input_placeholder')} ref={props.registerRef} type="text" disabled={disabled} onChange={(e) => props.onChange(e.target.value)} value={props.value}/>
            );
            break;
        case 2: //numeric
            return (
                <input placeholder={t('suggestion_input_placeholder')} ref={props.registerRef} type="number" disabled={disabled} onChange={(e) => props.onChange(e.target.value)} value={props.value}  min="0"/>
            );
            break;
        case 3: // date
            return (
                <DatePicker
                    ref={props.registerRef}
                    selected={date_value}
                    disabled={disabled}
                    onChange={(date) => dateChange(date)}
                    dateFormat="dd.MM.yyyy"
                />
            );
            break;
        case 4: //boolean
            return (
                <select ref={props.registerRef} className="nl-select" disabled={disabled} onChange={(e) => props.onChange(e.target.value)} value={props.value}>
                    <option>{t('select_pick_answer')}</option>
                    <option value="true">{t('select_true')}</option>
                    <option value="false">{t('select_false')}</option>
                </select>
            );
            break;
        case 5: //enum
            return (
                <select ref={props.registerRef} className="nl-select" disabled={disabled} onChange={(e) => props.onChange(e.target.value)} value={props.value}>
                    <option>{t('select_pick_answer')}</option>
                    {variants}
                </select>
            );
            break;
        case 6: //phone
            return (
                <div>
                    <PhoneInput
                        ref={props.registerRef}
                        country={'pl'}
                        value={props.value}
                        onlyCountries={["pl"]}
                        onChange = {(phone) => props.onChange(phone)}
                        inputClass="nl-input"
                        containerClass="nl-phone-container"
                        disabled={disabled}
                    />
                </div>
            );
            break;
        case 7: //email
            return (
                <input placeholder={t('suggestion_input_placeholder')} ref={props.registerRef} type="email" disabled={disabled} onChange={(e) => props.onChange(e.target.value)} value={props.value}/>
            );
            break;
    }
}
