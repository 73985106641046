import React, {useContext, useEffect, useState} from 'react';
import '../Styles/App.css';
import {BorrowerContext} from "./App";
import {ErrorPage, GenerateError, NinjaLoader, secondsToDays} from "./Tools";
import Feedback from "./Feedback";
import Api from "./api";
import {Trans, useTranslation} from "react-i18next";
import Loader from "react-loader-spinner";
import {useHistory} from "react-router-dom";
import {ReactComponent as CallIcon} from '../Images/Icons/call_icon.svg';
import {ReactComponent as LinkIcon} from '../Images/Icons/link_icon.svg';
import LogoPick from "./LogoPick";
import {Modal} from "react-bootstrap";

function  Distributing() {
    const borrower_context_data = useContext(BorrowerContext);
    const [isLoading, setIsLoading] = useState(true);
    const [error_resp, setError] = useState(null);
    const [distribution_result, setDistributingResult] = useState('');
    const [feedback_point, setFeedbackPoint] = useState(null);
    let history = useHistory();
    const { t, i18n } = useTranslation();


    const reApply = () => {
        setIsLoading(true);
        Api.post('/loan/reapply', {"params":{"id":borrower_context_data.loan.id}})
            .then(
                (result) => {
                    let response = result.data;
                    if(response.code === 0){
                        history.push('/review');
                    } else {
                        setError(GenerateError(response.errors[0].code));
                    }
                    setIsLoading(false);

                },

                (error) => {
                    setIsLoading(false);
                    setError(GenerateError());
                }
            );
    }

    const clearData = () => {
        history.push('/clear');
    }

    useEffect(() => {
        borrower_context_data.setActiveStep(2);
    },[]);
    useEffect(() => {
        function confirmLoan() {
            setIsLoading(true);
            Api.post('/loan/confirm', {"params":{"id":borrower_context_data.loan.id}})
                .then(
                    (result) => {
                        let response = result.data;
                        if(response.code === 0) {
                            if(response.data.is_distributed === true){

                                if(response.data.deal.offered_amount > 0 && response.data.deal.offered_term >0){
                                    let heading = <DistributionSuccessOffer
                                        offered_amount={response.data.deal.offered_amount}
                                        offered_term={response.data.deal.offered_term}
                                        buyer_name={response.data.deal.buyer.name}
                                        buyer_id={response.data.deal.buyer.id}
                                    />
                                    setDistributingResult(heading);
                                } else {
                                    let heading = <DistributionSuccess
                                        redirect_url={response.data.deal.redirect_url}
                                        company_url={response.data.deal.buyer.web_page_url}
                                        buyer_name={response.data.deal.buyer.name}
                                        buyer_id={response.data.deal.buyer.id}
                                    />
                                    setDistributingResult(heading);
                                }


                                setFeedbackPoint(0);
                            } else {
                                if(response.data.loan.can_reapply) {
                                    let heading = <DistributionFailWithReapply
                                        re_apply_button = {response.data.loan.can_reapply}
                                        clear_data = {clearData}
                                        on_reapply = {reApply}
                                        will_delete_date = {response.data.will_delete_date}
                                    />
                                    setDistributingResult(heading);
                                } else {
                                    let heading = <DistributionFail
                                        clear_data = {clearData}
                                        will_delete_date = {response.data.will_delete_date}
                                    />
                                    setDistributingResult(heading);
                                }


                                setFeedbackPoint(1);
                            }
                        } else {
                            setError(GenerateError(response.errors[0].code));
                        }
                        setIsLoading(false);

                    },

                    (error) => {
                        setIsLoading(false);
                        setError(GenerateError());
                    }
                )
        }
        confirmLoan();
    }, []);
    if (error_resp)
    {
        return <ErrorPage {...error_resp}/>
    }
    else
    {
        if(isLoading) {
            return (<DistributingLoader />);
        } else {
            return (
                <div>
                    <DistributingResult distribution_heading={distribution_result} result_point={feedback_point}/>
                </div>
            );
        }

    }


}

export default Distributing;

export function DistributingLoader() {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div className="loader-title">
                {t('distribution_loader_title')}
            </div>
            <div className="loader-text">
                {t('distribution_loader_text')}

            </div>
            <div className="loader-body">

                <Loader
                    type="TailSpin"
                    color="#1462D8"
                    height={100}
                    width={100}
                />
            </div>
            <div className="loader-small-text">
                {t('distribution_loader_message')}
            </div>
            <div className="loader-small-text top-margin">
                <Trans
                    i18nKey="distribution_have_question"
                    components={[<a className="nl-link-black" href="mailto:system@ninjalender.pl" />]}
                />
            </div>
        </div>
    );
}

export function DistributionFail(props) {
    const { t, i18n } = useTranslation();

    const [confirm_modal, setConfirmModal] = useState(false);

    const handleShow = function() {setConfirmModal(true)};
    const handleClose = function() {setConfirmModal(false)};

    const clearData = function() {
        props.clear_data();
        handleClose();
    };

    return (
        <div>
            <Modal
                show={confirm_modal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
                contentClassName="nl-modal"
                size="lg"
            >
                <div className="nl-modal-content">
                    <div className="nl-modal-header">

                    </div>
                    <div className="nl-modal-title">
                        {t("distribution_result_delete_data_confirm_title")}
                    </div>

                    <div className="nl-modal-body">
                        <Trans
                            i18nKey="distribution_result_delete_data_confirm_text"
                            components={[<strong />]}
                        />

                    </div>
                    <div className="nl-modal-footer centered_text">
                        <button className="nl-button-white" onClick={clearData}>{t('distribution_result_delete_data_confirm_submit')}</button>
                        <button className="nl-button" onClick={handleClose}>{t('distribution_result_delete_data_confirm_cancel')}</button>
                    </div>
                </div>

            </Modal>
            <div className="distribution-title">
                {t('distribution_result_unsuccess_title')}
            </div>
            <div className="distribution-text">
                {t('distribution_result_unsuccess_text')}
            </div>
            <div className="distribution-text">
                <Trans
                    i18nKey="distribution_result_unsuccess_note"
                    values={{
                        date: props.will_delete_date,
                    }}
                    components={[<span className="blue-mark-text" />]}
                />
            </div>

            <div>
                <div className="flex-center-row-container">
                    <div className="top-margin">
                        <a className="nl-simple-link" onClick={handleShow} >{t('distribution_result_delete_data')}</a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export function DistributionFailWithReapply(props) {
    const { t, i18n } = useTranslation();

    const [confirm_modal, setConfirmModal] = useState(false);

    const handleShow = function() {setConfirmModal(true)};
    const handleClose = function() {setConfirmModal(false)};

    const clearData = function() {
        props.clear_data();
        handleClose();
    };

    return (
        <div>
            <Modal
                show={confirm_modal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
                contentClassName="nl-modal"
                size="lg"
            >
                <div className="nl-modal-content">
                    <div className="nl-modal-header">

                    </div>
                    <div className="nl-modal-title">
                        {t("distribution_result_delete_data_confirm_title")}
                    </div>

                    <div className="nl-modal-body">
                        <Trans
                            i18nKey="distribution_result_delete_data_reapply_confirm_text"
                            components={[<strong />]}
                        />

                    </div>
                    <div className="nl-modal-footer centered_text">
                        <button className="nl-button-white" onClick={clearData}>{t('distribution_result_delete_data_reapply_confirm_submit')}</button>
                        <button className="nl-button" onClick={handleClose}>{t('distribution_result_delete_data_reapply_confirm_cancel')}</button>
                    </div>
                </div>

            </Modal>
            <div className="distribution-title">
                {t('distribution_result_unsuccess_title')}
            </div>
            <div className="distribution-text">
                {t('distribution_result_re_apply_pre_text')}
            </div>
            <div className="distribution-text">
                {t('distribution_result_re_apply_text')}
            </div>
            <div className="flex-center-row-container">
                <div className="top-margin">
                    <a className="nl-button" onClick={props.on_reapply} >{t('distribution_result_re_apply_button')}</a>
                </div>
            </div>
            <div className="distribution-text">
                <Trans
                    i18nKey="distribution_result_re_apply_note"
                    values={{
                        date: props.will_delete_date,
                    }}
                    components={[<span className="blue-mark-text" />]}
                />
            </div>


            <div>
                <div className="flex-center-row-container">
                    <div className="top-margin">
                        <a className="nl-simple-link" onClick={handleShow} >{t('distribution_result_delete_data')}</a>
                    </div>
                </div>
            </div>

        </div>
    );
}

export function DistributionSuccess(props) {
    const { t, i18n } = useTranslation();
    let next_step = '';
    let offer = '';

    if(props.redirect_url){
        next_step =
            <div className="distribution-text">
                <div>
                    <Trans
                        i18nKey="distribution_result_success_what_next_redirect"
                        values={{
                            partner_name: props.buyer_name,
                        }}
                        components={[<strong />]}
                    />
                </div>

                <div className="flex-center-row-container">
                    <div className="top-margin">
                        <a className="nl-button" href={props.redirect_url} target="_blank">{t('distribution_result_proceed')}</a>
                    </div>
                </div>
            </div>;
    } else {
        if(props.company_url){
            next_step =
                <div className="distribution-text">
                    <div>
                        <Trans
                            i18nKey="distribution_result_success_what_next_no_redirect"
                            values={{
                                partner_name: props.buyer_name,
                            }}
                            components={[<strong />]}
                        />
                    </div>
                    <div><CallIcon/></div>
                    <div className="flex-center-row-container">
                        <div className="top-margin">
                            <a className="nl-link" href={props.company_url} target="_blank"><LinkIcon/>&nbsp;{t('distribution_result_company_learn_more')}</a>
                        </div>
                    </div>
                </div>
            ;
        }
    }

    return (
        <div>
            <div className="centered_text"><LogoPick organization_id={props.buyer_id} class_name=""/></div>
            <div className="distribution-title">
                <Trans
                    i18nKey="distribution_result_success_title"
                    values={{
                        partner_name: props.buyer_name,
                    }}
                    components={[<strong />]}
                />
            </div>
            <div className="distribution-title">
                {next_step}
            </div>


        </div>
    );
}

export function DistributionSuccessOffer(props) {
    const { t, i18n } = useTranslation();
    let next_step = '';
    let offer = '';

    return (
        <div>
            <div className="centered_text"><LogoPick organization_id={props.buyer_id} class_name=""/></div>
            <div className="distribution-title">
                {t('distribution_result_success_offer_title')}
            </div>
            <div className="distribution-text">
                <Trans
                    i18nKey="distribution_result_success_offer_description"
                    values={{
                        partner_name: props.buyer_name,
                    }}
                    components={[<strong />]}
                />
            </div>
            <div className="distribution-text">
                <div>
                    <div className="loan-heading">
                        <div className="loan-heading-col">
                            <div className="heading-col-label">{t('loan_amount')}</div>
                            <div className="heading-col-text">{props.offered_amount}</div>
                        </div>
                        <div className="loan-heading-col">
                            <div className="heading-col-label">{t('loan_term')}</div>
                            <div className="heading-col-text">{secondsToDays(props.offered_term)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="distribution-text">
                <div className="distribution-text">
                    <div><strong>{t('distribution_result_success_offer_what_next')}</strong></div>
                    <div>
                        <Trans
                            i18nKey="distribution_result_success_offer_what_next_text"
                            values={{
                                partner_name: props.buyer_name,
                            }}
                            components={[<strong />]}
                        />
                    </div>
                    <div className="top-margin-20"><CallIcon/></div>
                    <div className="flex-center-row-container">
                        <div className="top-margin">
                            <a className="nl-link" href={props.company_url} target="_blank"><LinkIcon/>&nbsp;
                                <Trans
                                    i18nKey="distribution_result_company_learn_more"
                                    values={{
                                        partner_name: props.buyer_name,
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export function DistributingResult(props) {

    return (
        <div>
            <div>
                {props.distribution_heading}
            </div>
            <div>
                <Feedback point={props.result_point}/>
            </div>
        </div>

    );
}